import React from "react";
import PropTypes from "prop-types";
import { Badge } from "react-bootstrap";
import { adminUserPropType } from "../../Helpers/UserModel";

const ResultsTable = ({
  users,
  currentUserId,
  actionsColumn,
  isLoading,
  onRemove,
}) => {
  const isCurrentUser = (user) => user.id === currentUserId;

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Admin Name</th>
          <th scope="col">Admin Email</th>
          <th scope="col">Admin Type</th>
          {actionsColumn ? <th scope="col">Actions</th> : null}
        </tr>
      </thead>
      <tbody>
        {!isLoading && users.length
          ? users.map((user) => (
              <tr key={`row${user.id}`}>
                <td className="font-weight-bold">{user.name}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                {actionsColumn ? (
                  <td>
                    {!isCurrentUser(user) ? (
                      <i
                        className="fa fa-trash-alt"
                        aria-hidden="true"
                        onClick={() => onRemove(user)}
                        role="button"
                      />
                    ) : (
                      <Badge pill variant="primary">
                        You
                      </Badge>
                    )}
                  </td>
                ) : null}
              </tr>
            ))
          : null}
      </tbody>
    </table>
  );
};

ResultsTable.propTypes = {
  users: PropTypes.arrayOf(adminUserPropType).isRequired,
  currentUserId: PropTypes.number,
  isLoading: PropTypes.bool,
  actionsColumn: PropTypes.bool,
  onRemove: PropTypes.func,
};

ResultsTable.defaultProps = {
  currentUserId: null,
  actionsColumn: false,
  isLoading: false,
  onRemove: () => {},
};

export default ResultsTable;
