import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { SortableContainer } from "@jauntin/react-ui";
import { Field } from "redux-form";
import CardCollapse from "../../Components/CardCollapse";

const EventTypeCard = ({ name }) => (
  <div className="event-type-card card mb-2">
    <div className="card-body p-2">
      <b>{name}</b>
    </div>
  </div>
);

const EventTypesOrder = ({ editing, eventTypes, handleSortEnd, tileCount }) => {
  return (
    <>
      {editing ? (
        <CardCollapse
          headerContent={
            <>
              <strong>Event Type Order</strong>
              <div className="small">
                Drag and drop events to reorder (only top{" "}
                <span className="d-inline-block mx-2">
                  <Field
                    name="eventTileShowCount"
                    component={({ input }) => (
                      <select
                        {...input}
                        name={input.name}
                        id={input.name}
                        className="custom-select custom-select-sm"
                        style={{ paddingRight: 32 }}
                      >
                        <option value={9}>9</option>
                        <option value={6}>6</option>
                        <option value={3}>3</option>
                      </select>
                    )}
                  />
                </span>
                {} will show)
              </div>
            </>
          }
        >
          <SortableContainer
            items={eventTypes}
            component={EventTypeCard}
            onMoveEnd={handleSortEnd}
          />
        </CardCollapse>
      ) : (
        <Card>
          <Card.Header className="bg-transparent d-flex justify-content-between">
            <strong>Event Type Order</strong>
          </Card.Header>
          <Card.Body>
            <ol>
              {eventTypes.slice(0, tileCount).map((eventType) => (
                <li key={eventType.id}>{eventType.name}</li>
              ))}
            </ol>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

EventTypesOrder.propTypes = {
  editing: PropTypes.bool.isRequired,
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      identifier: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleSortEnd: PropTypes.func.isRequired,
};

export default EventTypesOrder;
